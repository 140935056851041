import React, {useState} from 'react';
import * as styles from './styles.module.scss';
import StyledButton from '../../../atoms/StyledButton/index';
import {AiOutlineArrowDown} from 'react-icons/ai';
import axios from "axios";

function GetForm() {

    const [email, setEmail] = useState("");
    const [fullName, setFullName] = useState("");
    const [subject, setSubject] = useState("");
    const [success, setSuccess] = useState(false);
    const [interests, setInterests] = useState([]);
    const [emailError, setEmailError] = useState('');
    const [nameError, setNameError] = useState('');

    const validateEmail = (e) => {
        setEmail(e);
        setEmailError(''); 
    };

    const validateSubject = (e) => {
        setSubject(e);
    }

    const validateFullName = (e) => {
        setFullName(e);
        setNameError('');
    }

    const validateInterests = (e) => {
        if (!interests.includes(e)) {
            setInterests([...interests, e]);
        } else {
            setInterests(interests.filter(item => item !== e));
        }
    }

    const clearFields = () => {
        setEmail('');
        setFullName('');
        setSubject('');
        setInterests([]);
    }

    const handleSubmit = async () => {
        let hasNameError = false;
        let hasEmailError = false;
    
        if (!fullName.trim()) {
          setNameError('Please enter your name');
          hasNameError = true;
        } else {
          setNameError('');
        }
    
        if (!email.trim()) {
          setEmailError('Please enter your email address');
          hasEmailError = true;
        } else {
          setEmailError('');
        }
    
        if (hasNameError || hasEmailError) {
          return; 
        }
    
        await axios({
            method: "post",
            url: "https://api.sendinblue.com/v3/smtp/email",
            data: {
                "sender": {
                    "email": email
                },
                "to": [
                    {
                        "email": "hr@mwizr.com",
                        "name": "MASTER WiZR"
                    }
                ],
                "subject": "Master WiZR contact form",
                "htmlContent": "<html>" +
                    "<body><p>Hello from " + fullName + "</p>" +
                    "<p>" + subject + "</p>" +
                    "<p>I'm interested in: </p>" +
                    "<ul>" + interests.map((interest) => {
                        return "<li>" + interest + "</li>";
                    }) + "</ul>" +
                    "<hr/>" +
                    "<p>This message has been sent from Master WiZR get started form.</p>" +
                    "</body>" +
                    "</html>"
            },
            headers: {
                "Content-Type": "application/json",
                "api-key":
                    "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",
            },
        })
            .then(function (response) {
                clearFields();
                setSuccess(true);
            })
            .catch(function (response) {
                setEmailError("Email address is invalid");
                console.log(response);
            });
    };

    const scrollTop = () => {
        window.scrollTo({top: 200, behavior: 'smooth'});
    };
    return (
        <div className={styles.getform}>
            {/* <div onClick={scrollTop} className={styles.arrow}>
                <AiOutlineArrowDown/>
            </div> */}
            <div className={styles.mainform}>
                <div className={styles.options}>
                    <p>I am interested in...</p>
                    <label>
                        <input type="checkbox" className={styles.optionInput}
                               onChange={() => validateInterests("Product Walkthrough")}
                               checked={interests.includes("Product Walkthrough")}
                        />
                        Product Walkthrough
                    </label>
                    <label>
                        <input type="checkbox" className={styles.optionInput}
                               onChange={() => validateInterests("Trying the solution for free 7 days")}
                               checked={interests.includes("Trying the solution for free 7 days")}
                        />
                      Free version (Studio light)
                    </label>
                    <label>
                        <input type="checkbox" className={styles.optionInput}
                               onChange={() => validateInterests("Get started and get the lowest possible rate")}
                               checked={interests.includes("Get started and get the lowest possible rate")}
                        />
                        Signing Up
                    </label>
                    <label>
                        <input type="checkbox" className={styles.optionInput}
                               onChange={() => validateInterests("Sign up for a new innovative Video solution in Beta")}
                               checked={interests.includes("Sign up for a new innovative Video solution in Beta")}
                        />
                        Job openings
                    </label>
                    <label>
                        <input type="checkbox" className={styles.optionInput}
                               onChange={() => validateInterests("Be reviewed for a job position")}
                               checked={interests.includes("Be reviewed for a job position")}
                        />
             Practical questions
                    </label>
                    <label>
                        <input type="checkbox" className={styles.optionInput}
                               onChange={() => validateInterests("Establish email contact")}
                               checked={interests.includes("Establish email contact")}
                        />
                  Join our mailing list for the latest news and updates
                    </label>
                </div>
                <div className={styles.formHolder}>
                    <input type='text' placeholder='Full Name' required
                           onChange={(e) => validateFullName(e.target.value)}
                           value={fullName}
                    />
                           {nameError && <span className={styles.error}>{nameError}</span>}
                    <input type='email' placeholder='Email Address' required
                           onChange={(e) => validateEmail(e.target.value)}
                           value={email}
                    />
                          {emailError && <span className={styles.error}>{emailError}</span>}
                    <textarea placeholder='Message'
                              onChange={(e) => validateSubject(e.target.value)}
                              value={subject}
                    ></textarea>
                    <div className={styles.btn}>
                        {/*<p>For job application, <label for="file-upload">Please submit your resume here</label></p>*/}
                        {/*<input id="file-upload" type="file"/>*/}
                        {
                            !success && (
                                <div className={styles.mainbtn}>
                                    <StyledButton extraClass="getStarted" className={styles.btn} title="Submit" icon email={true}
                                                  onClick={() => handleSubmit()}/>
                                </div>
                            )
                        }
                        {
                            success && (
                                <p className={styles.success}>Message successfully sent.</p>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default GetForm;